import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { SettingsService } from './settings.service';

//  const BASE_URL = environment.baseUrl;
//  const BASE_URLFORM = environment.baseUrlCredit;
@Injectable({
  providedIn: 'root'
})
export class CreditService {
  medio: 0 | 140 | 95= 0;
  telefono: string | null = null;

  // **: representa el resto de codigos
  codigoRespuesta: null | 'WS-OK' | 'WS-SL' | 'WS-PR' | 'RO-015' | 'N-OPR' | '**' = null;
  http = inject(HttpClient);
  settingService = inject(SettingsService);
  phoneWhatsapp = this.settingService.WP_PHONE;
  montos: any[] = [];
  creditoSimulado: any;

  constructor(
  ) { }
  getCuotas(monto: number, plazo: number): any {
    return this.http.post(this.settingService.URL_BASE+'calculadora/monto/cuota', {monto, plazo})
  }

  getPlazos(): any[] {
    return [
      {value: 6, viewValue: '6 meses'},
      {value: 12, viewValue: '12 meses'},
      {value: 15, viewValue: '15 meses'},
      {value: 18, viewValue: '18 meses'},
    ];
  }
  getMontos(inicio:number,fin:number,paso:number): any[]{
    this.montos = [];
    const importeMinimo = 500000;
    this.montos.push({value: importeMinimo, viewValue: importeMinimo.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")})
    for (let monto = inicio; monto <= fin; monto += paso) {
      //console.log(monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
    this.montos.push({
      value: monto,
      viewValue: monto.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    });
  }
    return this.montos;
  }

  sendCreditForm(creditForm: any): any {
    const headers = new HttpHeaders( {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer "' + sessionStorage.getItem('token')+ '"',
      'id': '' + sessionStorage.getItem('id'),
    });

    console.log('HEADERS', headers)
      return this.http.post(this.settingService.URL_BASE_CREDIT+'/solicitud/ingresar', creditForm, {headers:headers});
  }

  getTokenForm(): any {
    return this.http.get(this.settingService.URL_BASE_CREDIT + '/get/formulario');
  }

  getCreditoSimulado(item:any){
    return this.creditoSimulado ? this.creditoSimulado[item] : undefined;
  }

  setCreditoSimulado(item:any){
    this.creditoSimulado = item;
  }
}
