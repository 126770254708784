import {Injectable} from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public URL_BASE = environment.baseUrl;
  public URL_BASE_CREDIT = environment.baseUrlCredit;
  public WP_PHONE = environment.wpPhone;

  constructor() { }
}
