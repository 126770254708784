import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalServiceService {
  queryParams:string = '';
  urlParamsUTM= new  URLSearchParams();
  constructor() { }

  addQueryParams(urlParams: string) {
    this.queryParams = urlParams;
  }

  getQueryParams() {
    return this.queryParams;
  }

  addUTMParams(urlParams: URLSearchParams) {
    this.urlParamsUTM = urlParams;
    console.log('urlParams add',this.urlParamsUTM)
  }
  getUTMParams(key: string) {
    return this.urlParamsUTM.get(key);
  }

  setUTMParams(key: string, value: string) {
    console.log('setUTMParams',key,value)
    this.urlParamsUTM.set(key, value);
    console.log('urlParams set',this.urlParamsUTM)
  }


  public saveData(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }
  public getData(key: string) {
    return sessionStorage.getItem(key)
  }
  public removeData(key: string) {
    sessionStorage.removeItem(key);
  }
  public clearData() {
    sessionStorage.clear();
  }


}
